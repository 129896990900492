import { Controller } from '@hotwired/stimulus';
import { Sortable } from 'sortablejs';
import { put } from '@rails/request.js';

// Connects to data-controller="sortable-items"
export default class extends Controller {
  connect() {
    const sortable = Sortable.create(this.element, {
      onUpdate: (event) => {
        this.updatePosition(
          event.item.dataset.id,
          event.item.dataset.resourceKlass,
          event.item.dataset.parentKlass,
          event.newIndex
        );
      }
    });
  }

  async updatePosition(id, resourceKlass, parentKlass, position) {
    await put('/position_update', {
      body: {
        id: id,
        resource_klass: resourceKlass,
        parent_klass: parentKlass,
        position: position
      },
      contentType: 'application/json',
      responseKind: 'json'
    });
  }
}
